.home-links {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .home-links a {
    margin: 0 10px;
    text-decoration: none;
    color: #000;
  }